import Ashutosh from '@/assets/images/experts/ashutosh bhardwaj.png'
import Mayank from '@/assets/images/experts/mayank chandel.png'
import Ravi from '@/assets/images/experts/ravi bhatt.png'
import Varun from '@/assets/images/experts/varun patel.webp'
import StockSvg from '@/assets/images/icons/stock.svg'
import TrendSvg from '@/assets/images/icons/trend.svg'
import DateSvg from '@/assets/images/icons/date.svg'

import user1 from '@/assets/images/testimonials/user1.webp'
import user2 from '@/assets/images/testimonials/user2.webp'
import user3 from '@/assets/images/testimonials/user3.webp'
import user4 from '@/assets/images/testimonials/user4.webp'
import user5 from '@/assets/images/testimonials/user5.webp'

import messageImg from '@/assets/images/common/messageImg.png'
import BlueTick from '@/assets/images/common/BlueTick.png'
import PlayZone from '@/assets/images/common/PlayZone.png'
import ComunityPols from '@/assets/images/common/ComunityPols.png'
import TrackIdeas from '@/assets/images/common/TrackIdeas.png'
import MoreFeatures from '@/assets/images/common/MoreFeatures.png'


import Expert1 from '@/assets/images/deck/experts/ravi-bhatt.png';
import Expert2 from '@/assets/images/experts/varun patel.webp'
import Expert3 from '@/assets/images/deck/experts/alok.png';
import Expert4 from '@/assets/images/deck/experts/khushal.png';
import Expert5 from '@/assets/images/deck/experts/mayank.png';
import Expert6 from '@/assets/images/deck/experts/ashutosh.png';
import Expert7 from '@/assets/images/deck/experts/yash.png';

import KritiIndus from '@/assets/images/deck/stocks/kriti-industries.webp';
import IREDA from '@/assets/images/deck/stocks/IREDA.webp';
import KalyaniSteels from '@/assets/images/deck/stocks/kalyani-steels.webp';
import DataPatterns from '@/assets/images/deck/stocks/data-patterns.webp';
import Mitcon from '@/assets/images/deck/stocks/mitcon.webp';
import Ethos from '@/assets/images/deck/stocks/ethos.webp';
import AvonMore from '@/assets/images/deck/stocks/avonmore.webp';
import BPCL from '@/assets/images/deck/stocks/BPCL.webp';
import GTL from '@/assets/images/deck/stocks/GTL.webp';
import ShyamMetal from '@/assets/images/deck/stocks/ShyamMetal.webp';
import UCO from '@/assets/images/deck/stocks/UCO.webp';

import MAZDOCK from '@/assets/images/deck/stocks/MAZDOCK.webp';
import INOXGREEN from '@/assets/images/deck/stocks/INOXGREEN.webp';
import HBLPOWER from '@/assets/images/deck/stocks/HBLPOWER.webp';
import BEPL from '@/assets/images/deck/stocks/BEPL.webp';
import MOIL from '@/assets/images/deck/stocks/MOIL.webp';
import Carysil from '@/assets/images/deck/stocks/Carysil.webp';
import TATAINVEST from '@/assets/images/deck/stocks/TATAINVEST.webp';
import GRWRHITECH from '@/assets/images/deck/stocks/GRWRHITECH.webp';

export const homeDecksData = [
    {
      image: Ravi,
      name: 'Ravi Bhatt',
      experience: 'Exp: 10+ Years',
      info: '100.1% Gains in 36 Days',
      data: [
        {
          title: 'IREDA',
          info: 'Symbol',
          image: StockSvg
        },
        {
          title: 'Growth Titans',
          info: 'Deck',
          image: TrendSvg
        },
        {
          title: '1-1-2024',
          info: 'Entry',
          image: DateSvg
        },
        {
          title: '6-2-24',
          info: 'Exit',
          image: DateSvg
        }
      ]
    },
    {
      image: Ashutosh,
      name: "Ashutosh Bhardwaj",
      experience: "Exp: 10+ Years",
      info: '52.4% Gains in 61 Days',
      data: [
        {
          title: 'KSL',
          info: 'Symbol',
          image: StockSvg
        },
        {
          title: 'Growth Titans',
          info: 'Deck',
          image: TrendSvg
        },
        {
          title: '7-12-23',
          info: 'Entry',
          image: DateSvg
        },
        {
          title: '6-2-24',
          info: 'Exit',
          image: DateSvg
        }
      ]
    },
    {
      image: Mayank,
      name: "Mayank Chandel",
      experience: "Exp: 8+ Years",
      info: '40.0% Gains in 26 Days',
      data: [
        {
          title: 'KRITI',
          info: 'Symbol',
          image: StockSvg
        },
        {
          title: 'Trading Alphas',
          info: 'Deck',
          image: TrendSvg
        },
        {
          title: '17-1-24',
          info: 'Entry',
          image: DateSvg
        },
        {
          title: '12-2-24',
          info: 'Exit',
          image: DateSvg
        }
      ]
    },
    {
      image: Varun,
      name: "Varun Patel",
      experience: "Exp: 15+ Years",
      info: '30.7% Gains in 15 Days',
      data: [
        {
          title: 'DATAPATTNS',
          info: 'Symbol',
          image: StockSvg
        },
        {
          title: 'Growth Titans',
          info: 'Deck',
          image: TrendSvg
        },
        {
          title: '16-2-24',
          info: 'Entry',
          image: DateSvg
        },
        {
          title: '2-3-24',
          info: 'Exit',
          image: DateSvg
        }
      ]
    },
]

export const homeTestimonialData = [
    {
      image: user1,
      description: "After experiencing a loss in my previous investment journey, again I tried to give a try and found this OpiGo app where I started receiving Card Decks from SEBI registered experts and made my journey trust worthy with 6-11% avg returns.",
      userName: "Lohith Allamsetty",
      userStatus: "Managing Head, Cosmo"
    },
    {
      image: user2,
      description: "This is a novel and wonderful concept to take control of one's investment in stocks without relying on hearsay. It gives an opportunity to follow friends and experts in this field and make wise investment decision. Will like to see integration for direct investment through the app in near future.",
      userName: "Siddharth Parekh",
      userStatus: "Founder, Coffee Brew"
    },
    {
      image: user3,
      description: "A great app for beginners as well non finance people to learn and know about stocks and financial expertise. A great platform to grow your investment.",
      userName: "Vaibhav Shah",
      userStatus: "Managing Head, Cosmo"
    },
    {
      image: user4,
      description: "Seamless and easy to use app. The Cards and Polls feature is extremely helpful and effective to track stocks and make investment decisions. Now we can follow SEBI registered experts, get queries resolved and discuss investments - all in one place. All this in an accountable and reliable way.",
      userName: "Vismay Tejas Tolia",
      userStatus: "Product Designer, Cipla"
    },
    {
      image: user5,
      description: "Opigo is a perfect platform for beginners like me to have expert opinions on stocks, investment financial management services. It's more like social connect but in terms of trading or investment ideas. Have followed the leads of some people and have really benefitted from the same.",
      userName: "Jihan Parikh",
      userStatus: "Managing Head, Cosmo"
    },
]

export const homeFeaturesData = [
    {
      title: 'Stock Cards',
      description: 'Cards in OpiGo are like tweets on Twitter to Share Stock Ideas',
      image: messageImg,
      color: '#F86D58'
    },
    {
      title: 'Dynamic Scoring',
      description: 'Meant to differentiate the Ninjas from average investors',
      image: BlueTick,
      color: '#9CCBFB'
    },
    {
      title: 'PlayZone',
      description: 'Daily stock picking contests to practice & earn cash rewards',
      image: PlayZone,
      color: '#94DF93'
    },
    {
      title: 'Community Polls',
      description: 'Ask the community if you should Buy/Hold/Sell a stock',
      image: ComunityPols,
      color: '#FD942E'
    },
    {
      title: 'Track Ideas',
      description: 'Track 20k+ ideas, stocks & indices, in real-time',
      image: TrackIdeas,
      color: '#9780FF'
    },
    {
      title: 'More Features...',
      description: 'Social, research, forums, news & much more',
      image: MoreFeatures,
      color: '#FFA828'
    }
]

export const decksexpertsData = [
    {
        name: 'Ravi Bhatt',
        image: Expert1,
        exp: '10+'
    },
    {
        name: 'Varun Patel',
        image: Expert2,
        exp: '15+'
    },
    {
        name: 'Alok Daiya',
        image: Expert3,
        exp: '21+'
    },
    {
        name: 'Khushal Lakhani',
        image: Expert4,
        exp: '10+'
    },
    {
        name: 'Mayank Singh Chandel',
        image: Expert5,
        exp: '8+'
    },
    {
        name: 'Ashutosh Bhardwaj',
        image: Expert6,
        exp: '10+'
    },
    {
        name: 'Yash Birajdar',
        image: Expert7,
        exp: '7+'
    }
]

export const decksCardsData = [
    {
        image: Ravi,
        name: 'Ravi Bhatt',
        experience: 'Exp: 10+ Years',
        info: '100.1% Gains in 36 Days',
        entryOn: '1-Jan-24',
        exitOn: '6-Feb-24',
        stock: {
            title: 'IREDA',
            icon: IREDA
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '52.4% Gains in 61 Days',
        entryOn: '7-Dec-23',
        exitOn: '6-Feb-24',
        stock: {
            title: 'Kalyani Steels',
            icon: KalyaniSteels
        }
    },
    {
        image: Mayank,
        name: "Mayank Chandel",
        experience: "Exp: 8+ Years",
        info: '40.0% Gains in 26 Days',
        entryOn: '17-Jan-24',
        exitOn: '12-Feb-24',
        stock: {
            title: 'Kriti Industries',
            icon: KritiIndus
        }
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '30.7% Gains in 15 Days',
        entryOn: '16-Feb-24',
        exitOn: '2-Mar-24',
        stock: {
            title: 'Data Patterns',
            icon: DataPatterns
        }
    },
    {
        image: Ravi,
        name: 'Ravi Bhatt',
        experience: 'Exp: 10+ Years',
        info: '59.6% Gains in 32 Days',
        entryOn: '19-Dec-23',
        exitOn: '20-Jan-24',
        stock: {
            title: 'Mitcon',
            icon: Mitcon
        }
    },
    {
        image: Expert7,
        name: 'Yash Birajdar',
        experience: 'Exp: 7+ Years',
        info: '53.2% Gains in 143 Days',
        entryOn: '11-Sep-23',
        exitOn: '1-Feb-24',
        stock: {
            title: 'Ethos',
            icon: Ethos
        }
    },
    {
        image: Ravi,
        name: 'Ravi Bhatt',
        experience: 'Exp: 10+ Years',
        info: '47.7% Gains in 81 Days',
        entryOn: '4-Jan-24',
        exitOn: '24-Mar-24',
        stock: {
            title: 'Avonmore',
            icon: AvonMore
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '46.4% Gains in 60 Days',
        entryOn: '14-Dec-23',
        exitOn: '12-Feb-24',
        stock: {
            title: 'BPCL',
            icon: BPCL
        }
    },
    {
        image: Mayank,
        name: "Mayank Chandel",
        experience: "Exp: 8+ Years",
        info: '38.9% Gains in 13 Days',
        entryOn: '30-Jan-24',
        exitOn: '12-Feb-24',
        stock: {
            title: 'GTL Infrastructure',
            icon: GTL
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '38.3% Gains in 117 Days',
        entryOn: '30-Nov-23',
        exitOn: '26-Mar-24',
        stock: {
            title: 'SHYAMMETL',
            icon: ShyamMetal
        }
    },
    {
        image: Expert4,
        name: "Kushal Lakhani",
        experience: "Exp: 10+ Years",
        info: '32.6% Gains in 13 Days',
        entryOn: '23-Jan-24',
        exitOn: '5-Feb-24',
        stock: {
            title: 'UCO Bank',
            icon: UCO
        }
    },
    {
        image: Expert7,
        name: 'Yash Birajdar',
        experience: 'Exp: 7+ Years',
        info: '73.98% Gains in 22 Days',
        entryOn: '13-Jun-24',
        exitOn: '04-Jul-24',
        stock: {
            title: 'MAZDOCK',
            icon: MAZDOCK
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '32.01% Gains in 38 Days',
        entryOn: '14-May-24',
        exitOn: '20-Jun-24',
        stock: {
            title: 'INOXGREEN',
            icon: INOXGREEN
        }
    },
    {
        image: Expert7,
        name: 'Yash Birajdar',
        experience: 'Exp: 7+ Years',
        info: '25.97% Gains in 23 Days',
        entryOn: '08-Nov-23',
        exitOn: '01-Dec-23',
        stock: {
            title: 'HBLPOWER',
            icon: HBLPOWER
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '23.35% Gains in 4 Days',
        entryOn: '17-Jun-24',
        exitOn: '21-Jun-24',
        stock: {
            title: 'BEPL',
            icon: BEPL
        }
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '22.72% Gains in 23 Days',
        entryOn: '24-Apr-24',
        exitOn: '17-May-24',
        stock: {
            title: 'MOIL',
            icon: MOIL
        }
    },
    {
        image: Expert3,
        name: "Alok Daiya",
        experience: "Exp: 21+ Years",
        info: '22.70% Gains in 68 Days',
        entryOn: '07-Dec-23',
        exitOn: '13-Feb-24',
        stock: {
            title: 'Carysil Limited',
            icon: Carysil
        }
    },
    {
        image: Expert7,
        name: 'Yash Birajdar',
        experience: 'Exp: 7+ Years',
        info: '22.09% Gains in 15 Days',
        entryOn: '07-Feb-24',
        exitOn: '22-Feb-24',
        stock: {
            title: 'TATAINVEST',
            icon: TATAINVEST
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '21.15% Gains in 29 Days',
        entryOn: '08-Jan-24',
        exitOn: '06-Feb-24',
        stock: {
            title: 'GRWRHITECH',
            icon: GRWRHITECH
        }
    },
]

export const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
        src: 'https://www.youtube.com/watch?v=yIovK5jK4T8',
        type: 'video/youtube'
    }],
    techOrder: ['youtube'],
    youtube: {
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        controls: 0,
        disablekb: 1,
        fs: 0,
        cc_load_policy: 0,
        autohide: 1
    },
};
