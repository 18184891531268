import { useState } from "react";
import Lottie from "lottie-react";
import '../../../styles/getverified.scss'
import { B2CLayout } from '@/components/Layout'
import CheckLottie from '../../../assets/lottie_jsons/blue-checkmark.json'

const initData = {
    firstname: '',
    lastname: '',
    mobile: '',
    email: '',
    licenseType: 'SEBI Research Analyst',
    registrationNumber: '',
}

export const GetVerified = () => {

    const [data, setData] = useState(initData);

    const [showSuccessText, setShowSuccessText] = useState(false);
    const [err, setErr] = useState('');

    const onChangeData = (name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setErr('')
        setData({
            ...data,
            [name]: e.target.value
        })
    }

    const redirectToWhatsapp = () => {
        let message = "Hey \u{1F44B} I want to get Verified on OpiGo \n \n";

        message += `*First Name:* ${data.firstname}\n`;
        message += `*Last Name:* ${data.lastname}\n`;
        message += `*Mobile:* ${data.mobile}\n`;
        message += `*Email:* ${data.email}\n`;
        message += `*License Type:* ${data.licenseType}\n`;
        message += `*Registration Number:* ${data.registrationNumber}\n`;

        const encodedMessage = encodeURIComponent(message);

        const phoneNumber = "918451855382";

        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

        window.location.replace(whatsappLink);
    }

    const handleValidation = () => {
        const phoneNumberRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
        const emailRegex = /.+@[^@]+\.[^@]{2,}$/;

        const { firstname, lastname, mobile, email, licenseType, registrationNumber } = data;

        if (!firstname || !lastname || !mobile || !email || !licenseType || !registrationNumber) {
            setErr('Please fill up the all information.')
            return false;
        }

        if (!phoneNumberRegex.test(mobile)) {
            setErr('Please enter valid mobile number.')
            return false;
        }

        if (!emailRegex.test(email)) {
            setErr('Please enter valid email address.')
            return false;
        }

        return true;
    }

    const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValidData = handleValidation();
        if (isValidData) {
            redirectToWhatsapp();
        };
    }

    return (
        <B2CLayout>
            <div className="d-flex flex-column justify-content-center align-items-center container">
                <div className="lottie-box mt-3">
                    <Lottie animationData={CheckLottie} loop={false} />
                </div>

                <h1 className='text-dark mt-2 text-center title'>Become A Verified Expert On OpiGo</h1>
                <h4 className='description open-sans mt-0 mt-md-2 mb-0 mb-md-5' >Express your interest in obtaining a blue tick and joining our Decks as an expert!</h4>

                <div className='form-container'>
                    <form className="form" onSubmit={handleSubmitForm}>
                        <div className='row'>
                            <div className="col-12 col-md-6 input-container ">
                                <label htmlFor="firstname">First Name</label>
                                <input id="firstname" placeholder="Enter your first name" value={data.firstname} onChange={(e) => { onChangeData('firstname', e) }} />
                            </div>

                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="lastname">Last Name</label>
                                <input id="lastname" placeholder="Enter your last name" value={data.lastname} onChange={(e) => { onChangeData('lastname', e) }} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="mobile">Mobile Number</label>
                                <input id="mobile" type="number" placeholder="Enter your mobile number" value={data.mobile} onChange={(e) => { onChangeData('mobile', e) }} />
                            </div>

                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="email">Email ID</label>
                                <input id="email" placeholder="Enter your email id" value={data.email} onChange={(e) => { onChangeData('email', e) }} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="licence">License Type</label>
                                <select className="form-select" id="licence" value={data.licenseType} onChange={(e) => onChangeData('licenseType', e)}>
                                    <option>SEBI Research Analyst</option>
                                    <option>SEBI Investment Advisor</option>
                                    <option>Registered Portfolio Manager</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="registernumber">Registration No.</label>
                                <input id="registernumber" placeholder="Enter your Registration No." value={data.registrationNumber} onChange={(e) => { onChangeData('registrationNumber', e) }} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12">
                                <button className="submit-btn cursor-pointer" type="submit"> Submit </button>
                                {err ? <p className='success-text text-danger'> {err} </p> : null}
                                {showSuccessText ? <p className='success-text'>{'Our team will review your submission and get back:)'}</p> : null}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div style={{ marginBottom: '98px' }} />
        </B2CLayout>
    );
}
