import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/deck.scss';
import Carousel from 'react-multi-carousel'

import { ReactComponent as ThePrint } from '@/assets/images/featured_in/the_print.svg'
import { ReactComponent as VcCircle } from '@/assets/images/featured_in/vccircle.svg'
import { ReactComponent as YoutStory } from '@/assets/images/featured_in/your_story.svg'
import { ReactComponent as BusinessStandard } from '@/assets/images/featured_in/business_standard.svg'
import { ReactComponent as AniNews } from '@/assets/images/featured_in/ani_news.svg'

import { ReactComponent as ThePrintSmall } from '@/assets/images/featured_in/small/the_print.svg'
import { ReactComponent as VcCircleSmall } from '@/assets/images/featured_in/small/vccircle.svg'
import { ReactComponent as YoutStorySmall } from '@/assets/images/featured_in/small/your_story.svg'
import { ReactComponent as BusinessStandardSmall } from '@/assets/images/featured_in/small/business_standard.svg'
import { ReactComponent as AniNewsSmall } from '@/assets/images/featured_in/small/ani_news.svg'

import Research from '@/assets/images/icons/research.svg'
import Users from '@/assets/images/icons/users.svg'
import Trade from '@/assets/images/icons/trade.svg'

import LayerFilled from '@/assets/images/icons/layer-filled.svg';
import Messages from '@/assets/images/icons/messages.svg';
import Whatsapp from '@/assets/images/icons/whatsapp.svg';
import Money from '@/assets/images/icons/money.svg';

import { ReactComponent as LogoSvg } from '@/assets/images/logo.svg';
import DeckFeatureCard from '@/components/Elements/DeckFeatureCard';
import ExploreDeckCard from '@/components/Elements/ExploreDeckCard';
import DeckCard from '@/components/DeckCard';
import { Accordion } from 'react-bootstrap';
import DeckFooter from '@/components/Layout/DeckFooter';
import { useDecks } from '@/features/decks/api/getDecks';
import { getUtmMedium } from '@/utils/utility';
import { decksCardsData, decksexpertsData, videoJsOptions } from '@/utils/constant';
import { useNavigate } from 'react-router-dom';
import VideoJS from '@/components/Elements/VideoJS';

const GrowthTitans = () => {

    const navigate = useNavigate();
    const decksQuery: any = useDecks({ config: {} });
    const deckRef: any = useRef(null);

    const sliderRef = useRef<any>(null);
    const device = sliderRef.current?.state.deviceType === 'mobile' ? 1 : 3;

    const [currentSlide, setCurrentSlide] = useState(0);
    const [decksData, setDecksData] = useState<any[]>([]);
    const [lowestPackage, setLowestPackage] = useState('');

    const utmData = getUtmMedium();

    const handleNextSlide = () => {
        if (currentSlide >= decksCardsData?.length - device) { return }
        const nextSlide = currentSlide + 1;
        setCurrentSlide(nextSlide);
        if (sliderRef.current) {
            sliderRef.current.goToSlide(nextSlide);
        }
    };

    const handlePreviousSlide = () => {
        if (currentSlide === 0) { return }
        const nextSlide = currentSlide - 1;
        setCurrentSlide(nextSlide);
        if (sliderRef.current) {
            sliderRef.current.goToSlide(nextSlide);
        }
    }

    const filterDataBasedOnTime = () => {
        let { data } = decksQuery;
        let filterdData: any = [];
        let tempLowestPackage: any = Infinity;

        data?.map((deck: any) => {
            if (deck.title === 'Growth Titans') {
                deck?.channel_packages?.map((item: any) => {
                    if (!item.sTimebase) {
                        if (item.price < tempLowestPackage) {
                            tempLowestPackage = item.price;
                        }
                        filterdData.push(deck);
                    }
                })
            }
        });

        setDecksData(filterdData)
        setLowestPackage(tempLowestPackage)
    }

    useEffect(() => {
        if (!decksQuery?.isLoading && decksQuery?.data?.length > 0) {
            filterDataBasedOnTime();
        }
    }, [decksQuery.data])

    const navigateToPayment = () => {
        if (decksData.length > 0) {
            navigate(`/decks/purchase/${decksData[0].id}/${decksData[0].channel_packages[0].id}?${utmData}`)
        }
    }

    const stepData = [
        { svg: LayerFilled, text: 'Pick a Deck based on your goal' },
        { svg: Messages, text: 'Receive stock picks from experts' },
        { svg: Whatsapp, text: 'Get live alerts on WhatsApp' },
        { svg: Money, text: 'Start building your solid portfolio' },
    ];

    const HowItWorksStepper = () => {
        return (
            <div className='d-flex flex-row-reverse justify-content-between flex-md-column'>
                {/* Cards Section */}
                <div className='d-flex align-items-center justify-content-between works-card-container overflow-visible'>
                    {stepData.map((step, index) => (
                        <div className='works-card-box' key={index}>
                            <div className='image-box'>
                                <div className='element-image-box' />
                                <object data={step.svg} type="image/svg+xml" className="custom-svg"></object>
                            </div>
                            <h3 className='deck-common-body-lg info'>{step.text}</h3>
                        </div>
                    ))}
                </div>

                {/* Stepper Section */}
                <div className="stepper">
                    {stepData.map((_, index) => (
                        <React.Fragment key={index}>
                            <div className="step">{`0${index + 1}`}</div>
                            {index < stepData.length - 1 && <div className="connector"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }

    const components = [
        <ThePrintSmall className='py-1 mx-2 item' />,
        <VcCircleSmall className='py-1 mx-2 item' />,
        <YoutStorySmall className='py-1 mx-2 item' />,
        <BusinessStandardSmall className='py-1 mx-2 item' />,
        <AniNewsSmall className='py-1 mx-2 item' />,
    ];


    return (
        <div className='deck-body'>
            <section className='header-container header-container-deck-page'>
                <LogoSvg className='logo-svg cursor-pointer' />

                <div className='row inner-container'>
                    <div className='col-lg-6 col-12'>
                        <h1 className='deck-common-heading header-title'>Know Which Stocks To Buy & When!</h1>
                        <p className='deck-common-body-lg header-desc'>Receive goal-based stock picks from top-rated experts directly on WhatsApp</p>

                        <button className='deck-common-body-md header-button' onClick={navigateToPayment}>Subscribe Now</button>

                        <div className='header-info-container d-flex align-content-center justify-content-between justify-content-lg-start'>
                            <div>
                                <p className='info-title deck-common-body-md'>Combined Experience</p>
                                <p className='info-desc'>90+ Yrs</p>
                            </div>

                            <div>
                                <p className='info-title deck-common-body-md'>Accuracy</p>
                                <p className='info-desc'>82%</p>
                            </div>

                            <div>
                                <p className='info-title deck-common-body-md'>Trusted & reliable</p>
                                <p className='info-desc'>100%</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-12 my-auto'>
                        <div className='video-container'>
                            <VideoJS options={videoJsOptions} />
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= Featured Section ======= */}

            <section className='featured container overflow-hidden'>
                <h2 className='deck-common-body-lg text-center'>As Featured In</h2>

                <div className="d-none d-md-flex w-100 justify-content-around align-items-center" >
                    <ThePrint />
                    <VcCircle />
                    <YoutStory />
                    <BusinessStandard />
                    <AniNews />
                </div>

                <div className='d-block d-md-none slide-track'>
                    {Array(6).fill(components).flat().map((Component, index) => (
                        <React.Fragment key={index}>{Component}</React.Fragment>
                    ))}
                </div>
            </section>

            {/* ======= Features Section ======= */}

            <section className='features-section'>
                <h2 className='deck-common-sub-heading'>Say No To Shady Stock Tips</h2>
                <p className='deck-common-body-lg'>Here's Why You Should Rather Choose Decks</p>

                <div className='d-inline-flex justify-content-around align-items-center flex-wrap mt-4'>
                    <DeckFeatureCard icon={Research} title='Research Backed' desc='Every Stock Pick Comes with Verified Expert-Backed Research' />
                    <DeckFeatureCard icon={Users} title='Top-Rated Experts' desc='Unlock Stock Picks from 10+ SEBI Reg. Experts' />
                    <DeckFeatureCard icon={Trade} title='Transparent Investing' desc='Every Past Call Disclosed. No Shady Tips Here' hideBorder={true} />
                </div>
            </section>

            {/* ======= How It Works ======= */}

            <section className='works container '>
                <h2 className='text-center deck-common-sub-heading'>How it works</h2>
                <HowItWorksStepper />
            </section>

            {/* ======= Explore Decks ======= */}

            <section className='explore-decks' ref={deckRef}>
                <h2 className='text-center text-black mb-3 mb-lg-4 deck-common-sub-heading'>Explore our Deck</h2>
                <p className='text-black deck-common-body-md text-center text-capitalize'>Start Building Your Portfolio Today!</p>

                <div className='container d-flex flex-column align-items-center justify-content-center'>
                    {
                        decksQuery.isLoading && (
                            <p className='fw-medium mt-3'>Loading</p>
                        ) || (
                            decksData?.map((deck: any, index: number) => {
                                return <ExploreDeckCard key={index} deck={deck} selectedTime={undefined} utmData={utmData} />
                            })
                        )
                    }
                </div>
            </section>

            {/* ======= Experts ======= */}

            <section className='experts-section text-center px-4 overflow-hidden d-flex flex-column justify-content-center align-items-center'>
                <h2 className='text-capitalize deck-common-sub-heading'>Meet Our SEBI registered Experts</h2>

                <div className='d-none d-md-flex justify-content-between container my-5 mx-5'>
                    {
                        decksexpertsData?.map((expert: any) => {
                            return (
                                <div key={expert?.name} className='d-flex flex-column align-items-center'>
                                    <div className='expert-img-container'>
                                        <img src={expert?.image} className='expert-img' alt="Expert Image"/>
                                        <div className='expert-exp d-flex justify-content-center align-items-center'>
                                            {expert?.exp} YEARS
                                        </div>
                                    </div>


                                    <p className='mt-4 expert-name'>{expert?.name}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='d-flex d-md-none experts-slider gap-5'>
                    {
                        [...decksexpertsData, ...decksexpertsData, ...decksexpertsData]?.map((expert: any) => {
                            return (
                                <div key={expert?.name} className='d-flex flex-column align-items-center experts-slider'>
                                    <div className='expert-img-container'>
                                        <img src={expert?.image} className='expert-img' alt="Expert Image"/>
                                        <div className='expert-exp d-flex justify-content-center align-items-center'>
                                            {expert?.exp} YEARS
                                        </div>
                                    </div>


                                    <p className='mt-4 expert-name'>{expert?.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </section>

            {/* ======= Who should subscribe ======= */}

            <section className='subscribe-section d-flex flex-column align-items-center'>
                <h2 className='deck-common-sub-heading'>Who Should Subscribe?</h2>

                <div className='row d-flex justify-content-center mt-3 mt-lg-5 mx-3 mx-lg-0'>
                    <div className='col-md-5 col-12 d-flex jus subscribe-info-container'>
                        <div className='subscribe-number deck-common-sub-heading d-flex justify-content-center align-items-center'>
                            01
                        </div>
                        <p className='subscribe-text deck-common-body-xl'>People Who Are New To Investing</p>
                    </div>

                    <div className='col-md-5 col-12 d-flex jus subscribe-info-container'>
                        <div className='subscribe-number deck-common-sub-heading d-flex justify-content-center align-items-center'>
                            02
                        </div>
                        <p className='subscribe-text deck-common-body-xl'>Investors Seeking The Next Multibagger</p>
                    </div>

                    <div className='col-md-5 col-12 d-flex jus subscribe-info-container'>
                        <div className='subscribe-number deck-common-sub-heading d-flex justify-content-center align-items-center'>
                            03
                        </div>
                        <p className='subscribe-text deck-common-body-xl'>People Lacking Time For Research</p>
                    </div>

                    <div className='col-md-5 col-12 d-flex jus subscribe-info-container'>
                        <div className='subscribe-number deck-common-sub-heading d-flex justify-content-center align-items-center'>
                            04
                        </div>
                        <p className='subscribe-text deck-common-body-xl'>Investors Looking For Buy & Sell Picks</p>
                    </div>
                </div>
            </section>

            {/* ======= Still Not Convinced ======= */}

            <section className='not-convinced-section'>
                <div className='d-flex justify-content-center justify-content-md-between'>
                    <div className='d-flex flex-column align-items-center align-items-md-start'>
                        <h2 className='title deck-common-sub-heading text-md-start'>Still Not Convinced?</h2>
                        <p className='desc deck-common-body-xl mt-3 mb-3 mb-lg-0'>Here Are Some Top Picks By Our Experts</p>
                    </div>

                    <div className='d-none d-md-flex'>
                        <div className={`icon-box d-flex justify-content-center align-items-center`} onClick={handlePreviousSlide}>
                            <i className="bi bi-arrow-left arrow" />
                        </div>
                        <div className={`icon-box  d-flex justify-content-center align-items-center active`} onClick={handleNextSlide}>
                            <i className="bi bi-arrow-right arrow" />
                        </div>
                    </div>

                </div>

                <div className='carousel-container mt-0 mt-md-5'>
                    <Carousel
                        ref={sliderRef}
                        renderButtonGroupOutside={true}
                        additionalTransfrom={0}
                        arrows={false}
                        draggable={false}
                        centerMode={false}
                        focusOnSelect={false}
                        infinite={false}
                        renderDotsOutside={false}
                        responsive={{
                            main: {
                                breakpoint: {
                                    max: 3000,
                                    min: 2000
                                },
                                items: 4,
                            },
                            samllTabss: {
                                breakpoint: {
                                    max: 2000,
                                    min: 1500
                                },
                                items: 3,
                            },
                            samllTabs: {
                                breakpoint: {
                                    max: 1500,
                                    min: 1300
                                },
                                items: 2.8,
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1300,
                                    min: 1000
                                },
                                items: 2.3,
                            },
                            mobile: {
                                breakpoint: {
                                    max: 1000,
                                    min: 0
                                },
                                items: 1,
                            },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        showDots={false}
                        sliderClass="mt-4 mt-md-0"
                        slidesToSlide={1}
                        containerClass='p-0 p-md-4 ps-md-0'
                    >
                        {
                            decksCardsData?.map((deck: any, index: number) => {
                                return (
                                    <div key={`deck_${index}`} className='d-flex align-items-center justify-content-center'>
                                        <DeckCard {...deck} key={index} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>

                    <div className='d-flex mt-3'>
                        <div className='d-flex d-md-none align-self-center mx-auto'>
                            <div className={`icon-box d-flex justify-content-center align-items-center`} onClick={handlePreviousSlide}>
                                <i className="bi bi-arrow-left arrow" />
                            </div>
                            <div className={`icon-box  d-flex justify-content-center align-items-center active`} onClick={handleNextSlide}>
                                <i className="bi bi-arrow-right arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* =============== FAQ's =============== */}

            <section className='faq mt-0 mt-md-4' >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="title-main text-center mt-4 mt-md-5 fw-medium">{"FAQ’s"}</h2>
                        </div>
                        <div className="col-12 p-0">
                            <Accordion>
                                <Accordion.Item eventKey="faq_0">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >What are Decks?</span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            Decks on OpiGo provide goal-based stock picks from top-rated experts. Each Deck focuses on specific goals, such as short-term and medium-term investments. Users can select Decks based on their investment objectives and receive real-time entry and exit calls directly on WhatsApp.
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="faq_1">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >How does it work?</span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            Decks are goal-based subscriptions that provide high-quality stock picks. Simply select a Deck based on your investment goals and complete the payment. Once subscribed, you will start receiving stock picks on WhatsApp and the OpiGo app. This means you no longer need to spend time researching. Just follow the expert picks and make money in the stock market.
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="faq_2">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >I am new to investing, how do I get started?</span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            Stock research demands significant time and knowledge, making it challenging for new investors to choose the right stocks. Decks is the perfect solution for this. Simply select a Deck and subscribe based on your goals. Once subscribed, you'll receive stock picks from top-rated experts, with real-time alerts on when to buy and sell, sent directly to your WhatsApp.
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="faq_3">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >Are these experts SEBI-registered? </span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            Yes, all stock picks through Decks come from SEBI-Registered Research Analysts. It's important to note that while OpiGo facilitates these picks, we are neither registered with SEBI nor do we provide any investment advice. We also do not promote any particular SEBI registered expert. OpiGo serves as a tech enabler, delivering goal-based calls from registered experts.
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="faq_4">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >What happens after I pay and subscribe?</span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            Once you subscribe to a Deck, you'll receive subsequent stock picks from any of our experts via WhatsApp and app notifications. Please note that these stock picks are sent when our experts identify favorable opportunities, so you will receive stock picks on the go, not immediately after payment.
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="faq_5">
                                    <Accordion.Header className='py-md-3 py-1' ><span className='deck-common-body-lg' >Who can I contact if I still have questions?                                  </span></Accordion.Header>
                                    <Accordion.Body>
                                        <span className='deck-common-body-md'>
                                            You can reach out to us on our Whatsapp Helpline for any further questions. Use this link:{' '}
                                            <a href="https://shorturl.at/kzLO6">https://shorturl.at/kzLO6</a>
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>

            <DeckFooter />


            {/* ======== Offer Section ======= */}
            {
                decksQuery?.data?.length && (
                    <div className='d-flex align-content-center justify-content-between d-md-none position-fixed bottom-0 w-100 offer-section overflow-hidden'>
                        <div>
                            <h3 className='title-offer'>Starts at &#8377;{lowestPackage}</h3>
                            <p className='desc-offer'><span className='text-decoration-line-through' style={{ fontSize: '0.875rem' }} >&#8377;999</span> Special Launch Offer</p>
                        </div>

                        <button onClick={navigateToPayment}>Subscribe Now</button>
                    </div>
                ) || null
            }
        </div>
    )
}

export default GrowthTitans;
