import React from 'react';
import './styles.scss'


interface DeckCardProps {
    image: string;
    name: string;
    experience: string;
    info: string;
    entryOn: string;
    exitOn: string;
    stock: {
        title: string;
        icon: any
    }
}

const DeckCardUpdated = (props: DeckCardProps) => {
    return (
        <div className='deck-card overflow-visible'>
            <div className='badge-img'>
                <p className='badge-text deck-common-body-lg'>{props.info}</p>
                <div className='badge-new' />
            </div>

            <div className='expert'>
                <div className='expert-image-box'>
                    <img src={props.image} className='expert-image' />
                </div>

                <div className='expert-info'>
                    <h3 className='deck-common-body-lg'>{props.name}</h3>
                    <p className='deck-common-body-md'>{props.experience}</p>
                </div>
            </div>

            <div className='stock-container'>
                <div className='stock-image-box'>
                    <img src={props.stock.icon} className='stock-image' />
                </div>

                <div className='stock-info'>
                    <h4>Stock</h4>
                    <p className='deck-common-body-md'>{props.stock.title}</p>
                </div>
            </div>

            <div className='entry-exit-container'>
                <div>
                    <h3 className='deck-common-body-md'>Entry on</h3>
                    <p className='deck-common-body-md'>{props.entryOn}</p>
                </div>

                <div>
                    <h3 className='deck-common-body-md'>Exit on</h3>
                    <p className='deck-common-body-md'>{props.exitOn}</p>
                </div>
            </div>
        </div>
    )
}

export default DeckCardUpdated