import { useRoutes } from 'react-router-dom';
import { PrivacyPolicy, RefundPolicy, TermsOfUse } from '@/features/misc';
import { PaymentStatus, Purchase } from '@/features/decks';
import { HomePage } from "@/features/misc/pages/HomePage";
import { GetVerified } from "@/features/misc/pages/GetVerified";
import DeckNew from '@/features/misc/pages/DeckNew';
import ChooseDeck from '@/features/misc/pages/ChooseDeck';
import GrowthTitans from '@/features/misc/pages/GrowthTitans';
import TradingAlphas from '@/features/misc/pages/TradingAlpha';

export const AppRoutes = () => {

    const commonRoutes = [
        { path: '/', element: <HomePage /> },
        {
            path: '/decks', element: <DeckNew />
        },
        {
            path: '/decks/choose', element: <ChooseDeck />
        },
        {
            path: '/decks/choose/:coupon', element: <ChooseDeck />
        },
        {
            path: '/decks/choose/growth-titans', element: <GrowthTitans />
        },
        {
            path: '/decks/choose/trading-alpha', element: <TradingAlphas />
        },
        {
            path: '/getverified', element: <GetVerified />
        },
        {
            path: '/decks/purchase/:deckId/:packageId', element: <Purchase />,
        },
        {
            path: '/decks/purchase/paymentStatus', element: <PaymentStatus />,
        },
        {
            path: '/terms_of_use', element: <TermsOfUse />
        },
        {
            path: '/privacy_policy', element: <PrivacyPolicy />
        },
        {
            path: '/refund_policy', element: <RefundPolicy />
        },
    ];
    const element = useRoutes([...commonRoutes]);
    return (<>{element} </>);
}
