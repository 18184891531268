import React from 'react';
import '../../styles/deck.scss';

interface DeckFeatureCardProps {
    icon?: string;
    title?: string;
    desc?: string;
    containerClass?: string;
    titleClass?: string;
    hideBorder?: boolean;
}

const DeckFeatureCard = ({ icon, title, desc, containerClass, titleClass, hideBorder }: DeckFeatureCardProps) => {
    return (
        <div className={`deck-feature-card ${containerClass ?? ''} ${hideBorder ? 'border-0' : ''}`}>
            <div className='img-container d-flex justify-content-center align-items-center'>
                <object data={icon} type="image/svg+xml" className="custom-svg"></object>
            </div>

            <div>
                <h2 className={`${titleClass} deck-common-body-xl`}>{title}</h2>
                <p className='deck-common-body-md'>{desc}</p>
            </div>
        </div>
    )
}

export default DeckFeatureCard
