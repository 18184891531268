import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Deck } from '../types';

// export const getDecks = (): any => {
//   return MockData
// };

export const getDecks = (): Promise<Deck[]> => {
  return axios.get(`/public/explores`);
};

type QueryFnType = typeof getDecks;

type UseDecksOptions = {
  config?: QueryConfig<QueryFnType>
}

export const useDecks = ({ config }: UseDecksOptions) => {
  return useQuery<ExtractFnReturnType<any>>({
    queryKey: ['decks'],
    queryFn: () => getDecks(),
    ...config,
  });
};
