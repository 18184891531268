import { Header } from '@/components/Elements';
import GooglePlay from '@/assets/images/app_stores/google_play.svg'
import AppStore from '@/assets/images/app_stores/app_store.svg'
import PaymentSuccessSvg from '@/assets/images/common/success_tick.svg'
import { Helmet } from 'react-helmet-async';

export interface IPaymentStatusProps {
}

export function PaymentStatus(props: IPaymentStatusProps) {
  return (
    <section className='bg-light vh-100' >
      <Header backNav={false} />
      <Helmet>
        <title>Payment status</title>
      </Helmet>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center text-center">
            <div className="container bg-white p-3 py-4 rounded-3">
              <img src={PaymentSuccessSvg} alt="Success" />
              <h3 className='text-green' style={{ fontSize: '28px' }} >Payment successful</h3>
              <p className="fs-16" style={{ color: "#706C6C" }} >You will receive the updates on your Whatsapp</p>
              <br />
              <p className="fs-28 mt-5">Trusted by 25,000+ Investors</p>
              <div className="d-flex align-content-center justify-content-center mt-3">
                <a href="https://play.google.com/store/apps/details?id=com.OpiGo1final">
                  <img src={GooglePlay} alt="GooglePlay" />
                </a>
                <div className="mx-2"></div>
                <a href="https://apps.apple.com/in/app/opigo/id1619955231">
                  <img src={AppStore} alt="AppStore" />
                </a>
              </div>
              <br />
              <p className="fs-16 text-warning">Download our app for a better experience</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

